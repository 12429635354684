import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FindAllPermissionDto,
  PermissionCompactDto,
  PermissionDto,
  ResponsePagingDto,
  ResponseSuccessDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  tagTypes: ['permissions', 'permissions_detail'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getPermissions: builder.query<ResponsePagingDto<PermissionDto>, FindAllPermissionDto>({
      query: (params) => ({
        url: '/permissions',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ permissionId }) => ({
              type: 'permissions',
              id: permissionId
            }))
          : ['permissions']
    }),

    getPermissionsOptions: builder.query<ResponsePagingDto<PermissionCompactDto>, FindAllPermissionDto>({
      query: (params) => ({
        url: '/permissions/options',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows.push(...newItems.data.rows);
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: ['permissions']
    }),

    getPermissionDetail: builder.query<ResponseSuccessDto<PermissionDto>, number>({
      query: (permissionId) => ({ url: `/permissions/${permissionId}`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'permissions_detail', id: result.data.permissionId }] : [])
    })
  })
});

export const { useGetPermissionsOptionsQuery, useGetPermissionsQuery, useGetPermissionDetailQuery } = permissionsApi;
