import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'components';
import dayjs from 'dayjs';
import { useInternalAnnouncementsPaging, useSearchParamsForm } from 'hooks';
import { internalAnnouncementsMessages, messages } from 'messages';
import { forwardRef, useImperativeHandle } from 'react';
import { FindAllInternalAnnouncementDto, InternalAnnouncementDto } from 'types';
import { DATE_TIME_FORMAT } from 'utils';

type InternalAnnouncementsTableProps = {
  filterData: Omit<FindAllInternalAnnouncementDto, 'pageIndex' | 'pageSize'>;
  onRowClick: (record: InternalAnnouncementDto) => void;
};
export type InternalAnnouncementsTableRefProps = {
  resetPage: () => void;
};
const InternalAnnouncementsTable = forwardRef<InternalAnnouncementsTableRefProps, InternalAnnouncementsTableProps>(
  ({ filterData, onRowClick }, ref) => {
    useImperativeHandle(ref, () => ({
      resetPage: resetPage
    }));
    const { setSearchParam } = useSearchParamsForm();

    const {
      data: internalAnnouncements,
      count,
      pageIndex,
      handleChangePage,
      isLoading,
      resetPage
    } = useInternalAnnouncementsPaging({ ...filterData });

    const columns: ColumnsType<InternalAnnouncementDto> = [
      {
        title: internalAnnouncementsMessages.title,
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: internalAnnouncementsMessages.status,
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => <Typography.Text className='text-sm'>{record.status.name || '-'}</Typography.Text>
      },
      {
        title: internalAnnouncementsMessages.organizationUnit,
        dataIndex: 'organizationUnit',
        key: 'organizationUnit',
        render: (_, record) => (
          <Typography.Text className='text-sm'>
            {record.organizationUnits?.map((org) => org.name).join(', ') || '-'}
          </Typography.Text>
        )
      },
      {
        title: messages.updateAt,
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (val) => (
          <Typography.Text className='text-sm'>{val ? dayjs(val).format(DATE_TIME_FORMAT) : '-'}</Typography.Text>
        )
      },
      {
        title: internalAnnouncementsMessages.viewedCount,
        key: 'viewedCount',
        dataIndex: 'viewedCount',
        render: (val) => <Typography.Text className='text-sm'>{val}</Typography.Text>
      },
      {
        title: internalAnnouncementsMessages.confirmedCount,
        key: 'confirmedCount',
        dataIndex: 'confirmedCount',
        render: (val, record) => (
          <Typography.Text className='text-sm'>{record.isRequestConfirm ? val : '-'}</Typography.Text>
        )
      }
    ];

    return (
      <Table
        loading={isLoading}
        currentPage={pageIndex}
        count={count}
        handleChangePage={handleChangePage}
        columns={columns}
        dataSource={internalAnnouncements || []}
        rowKey={(record: InternalAnnouncementDto) => record.internalAnnouncementId}
        onRow={(record: InternalAnnouncementDto) => ({
          onClick: () => {
            onRowClick(record);
            setSearchParam('IA', record.internalAnnouncementId);
          }
        })}
      />
    );
  }
);

export default InternalAnnouncementsTable;
