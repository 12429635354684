import { message } from 'antd';
import { isServerUploadFile, ServerUploadFile } from 'components/common/upload/ServerUpload';
import { findIndex } from 'lodash';
import { useDeleteFileMutation, useUploadFileMutation } from 'services';
import { FilesBucketNames } from 'utils';

export function useUpload() {
  const [onUpload, { isLoading }] = useUploadFileMutation();
  const [onDelete, { isLoading: isLoadingDelete }] = useDeleteFileMutation();

  const onUploadFiles = async (files: ServerUploadFile[]): Promise<number[]> => {
    const uploadPromises = Array.from(files).map((file) => {
      if (isServerUploadFile(file)) return file.fileId;
      if (file.originFileObj) {
        return onUpload({
          file: file.originFileObj,
          bucketName: FilesBucketNames.PUBLIC
        })
          .unwrap()
          .then((rs) => rs.data?.fileId!)
          .catch((err) => message.error(err));
      }
      return 0;
    });
    try {
      const responses = await Promise.all(uploadPromises);
      return responses.filter((fileId) => fileId && typeof fileId === 'number').map((fileId) => fileId as number);
    } catch (error) {
      return [];
    }
  };

  const onDeleteFiles = async (files: number[]) => {
    for (const item of files) {
      try {
        await onDelete(item).unwrap();
      } catch (error) {}
    }
  };

  const handleMultiUpload = async (uploadFiles: ServerUploadFile[], originFiles?: number[]): Promise<number[]> => {
    if (originFiles && originFiles.length > 0) {
      const deleteFiles = originFiles.filter((file) => findIndex(uploadFiles, { fileId: file }) === -1);
      if (deleteFiles.length > 0) {
        await onDeleteFiles(deleteFiles);
      }
    }
    if (uploadFiles) {
      return await onUploadFiles(uploadFiles);
    }
    return [];
  };

  return {
    handleMultiUpload,
    isLoading: isLoading || isLoadingDelete
  };
}
