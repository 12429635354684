import { useGetAllShiftsOptionsQuery } from 'services';
import { FindAllShiftOptionDto, UseQueryOptionsType } from 'types';

export function useAllShiftsOptions({ nowTime }: FindAllShiftOptionDto, options?: UseQueryOptionsType) {
  const {
    data: shiftsOptionsResponse,
    error,
    isLoading,
    isFetching,
    refetch
  } = useGetAllShiftsOptionsQuery(
    {
      nowTime
    },
    {
      ...options
    }
  );

  return {
    shiftsOptions: shiftsOptionsResponse?.data,
    count: shiftsOptionsResponse ? Object.values(shiftsOptionsResponse.data).length : 0,
    isLoading: isFetching || isLoading,
    refetch,
    error
  };
}
