import { io } from 'socket.io-client';
import { NotificationDto } from 'types';
import { configuration, LOCAL_STORAGE_KEY, NOTIFICATION_EVENT, refreshTokenApi } from 'utils';
let refreshCount = 5;
export const notificationsSocketClient = io(configuration.NOTIFICATION_SOCKET_URL, {
  transports: ['websocket'],
  auth: {
    token: localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
  }
});

notificationsSocketClient.on(NOTIFICATION_EVENT.CONNECT, () => {
  refreshCount = 5;
});

notificationsSocketClient.on(NOTIFICATION_EVENT.CONNECT_ERROR, (error) => {
  refreshCount -= 1;
  reconnectNotificationSocket();
});

notificationsSocketClient.on(NOTIFICATION_EVENT.UNAUTHORIZED, (error) => {
  refreshCount -= 1;
  reconnectNotificationSocket();
});

export async function connectNotificationSocket() {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  if (accessToken) {
    notificationsSocketClient.auth = {
      token: accessToken
    };
    notificationsSocketClient.connect();
  }
}

export async function disconnectNotificationSocket() {
  notificationsSocketClient.disconnect();
}

export async function reconnectNotificationSocket() {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);

  if (refreshToken && refreshCount > 0) {
    const responseToken = await refreshTokenApi(refreshToken, 'notification-socket');

    if (responseToken?.data.accessToken) {
      const accessToken = responseToken.data.accessToken;
      notificationsSocketClient.auth = {
        token: accessToken
      };
      notificationsSocketClient.connect();
    }
  }
}

export function onReceiveNotification(callback?: (data: NotificationDto) => void) {
  notificationsSocketClient.on(NOTIFICATION_EVENT.NOTIFICATION, (data: NotificationDto) => {
    callback?.(data);
  });
}

export function offReceiveNotification() {
  notificationsSocketClient.off(NOTIFICATION_EVENT.NOTIFICATION);
}
