import { Form, Typography } from 'antd';
import {
  joinConversation,
  outConversation,
  sendMessage,
  useGetConversationDetailQuery,
  useUploadFileMutation
} from 'services';
import { CONVERSATION_TYPE, FilesBucketNames, MESSAGE_TYPE } from 'utils';
import MessageList from './MessageList';
import { internalChatMessages } from 'messages';
import { Avatar, ChatBox } from 'components/common';
import { useEffect } from 'react';
import { FileUpload } from 'types';

type ChatBoxProps = {
  conversationId: string;
};

const ConversationBox: React.FC<ChatBoxProps> = ({ conversationId }) => {
  const { data: conversationDetailRes } = useGetConversationDetailQuery(conversationId);
  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (conversationId) {
      joinConversation({ conversationId });
    }
    return () => {
      outConversation({ conversationId });
    };
  }, [conversationId]);

  const [form] = Form.useForm();

  const onSubmitMessage = (value: string) => {
    sendMessage({ conversationId, content: value, type: MESSAGE_TYPE.TEXT });
  };

  const onSubmitFile = (files: FileUpload[]) => {
    for (const file of files) {
      uploadFile({
        bucketName: FilesBucketNames.PUBLIC,
        file: file
      })
        .unwrap()
        .then((rs) => {
          const isImage = rs.data.fileType.includes('image');
          sendMessage({
            conversationId,
            content: rs.data.fileId.toString(),
            type: isImage ? MESSAGE_TYPE.IMAGE : MESSAGE_TYPE.FILE
          });
        });
    }
  };

  const conversation = conversationDetailRes?.data;

  return (
    <div className='flex h-full flex-col p-5'>
      <div className='mb-2 flex items-center gap-x-2'>
        <Avatar key={conversation?.conversationId} fileId={conversation?.image} name={conversation?.name} size={40} />
        <div className='flex flex-col'>
          <Typography.Title level={4} className='mb-0'>
            {conversation?.name}
          </Typography.Title>
          {conversation?.type === CONVERSATION_TYPE.GROUP && (
            <Typography.Text type='secondary' className='text-xs'>
              {conversation.participants.length} {internalChatMessages.participants}
            </Typography.Text>
          )}
        </div>
      </div>
      <MessageList conversationId={conversationId} />
      <ChatBox onSendFiles={onSubmitFile} onSendMessage={onSubmitMessage} />
    </div>
  );
};

export default ConversationBox;
