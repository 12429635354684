import { Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import type { UploadRequestOption as RcCustomRequestOptions, UploadRequestError } from 'rc-upload/lib/interface';
import * as XLSX from 'xlsx';
import Excel, { ExcelProps } from './Excel';
import { isValidJson } from 'utils';

type UploadExcelProps = UploadProps & {
  excelProps?: Omit<ExcelProps, 'data'>;
};

export type UploadExcelDataType = {
  excelData: (string | number)[][];
};

const UploadExcel = ({ excelProps, ...props }: UploadExcelProps) => {
  const fileList = props.fileList ? (Array.isArray(props.fileList) ? props.fileList : [props.fileList]) : undefined;

  const handleUpload = async (options: RcCustomRequestOptions) => {
    const { onError, onSuccess, file } = options;
    try {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils
          .sheet_to_json(worksheet, { header: 1 })
          .filter((row: any) => row.some((cell: any) => cell !== undefined && cell !== null && cell !== ''));

        onSuccess?.({
          excelData: jsonData
        });
      };

      reader.readAsArrayBuffer(file as RcFile);
    } catch (error) {
      onError?.(error as UploadRequestError);
    }
  };

  return (
    <Upload
      {...props}
      fileList={fileList}
      itemRender={(_origin: React.ReactElement, file: UploadFile<UploadExcelDataType> | string) => {
        if (!file) return;
        let data;
        if (typeof file === 'string') {
          if (!isValidJson(file)) {
            return;
          }

          data = JSON.parse(file);
        } else if (file.response?.excelData) {
          data = file.response.excelData;
        }

        return <Excel {...excelProps} data={data} />;
      }}
      accept='.xls,.xlsx'
      customRequest={handleUpload}
    />
  );
};
export default UploadExcel;
