import { List, Typography } from 'antd';
import { Avatar, InputSearch } from 'components/common';
import { useUsersOptions } from 'hooks';
import { UserCompactDto } from 'types';
import VirtualList from 'rc-virtual-list';
import { useState } from 'react';
import { usersMessages } from 'messages';

type UserListProps = {
  onClickItem?: (value: UserCompactDto) => void;
};

const UserList: React.FC<UserListProps> = ({ onClickItem }) => {
  const [keyword, setKeyword] = useState('');

  const { usersOptions, handleLoadMore, isLoading } = useUsersOptions({
    keyword
  });

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) <= 1) {
      handleLoadMore();
    }
  };

  return (
    <div className='flex max-h-full flex-col px-5 pt-5'>
      <InputSearch placeholder={usersMessages.fullName} onChange={(e) => handleSearch(e.toString())} />
      <List loading={isLoading} className='-mx-5 max-h-[calc(100%-48px)] overflow-auto'>
        <VirtualList itemKey='conversationId' data={usersOptions} fullHeight onScroll={onScroll}>
          {(item: UserCompactDto) => (
            <List.Item
              key={item.userId}
              className='cursor-pointer px-5 hover:bg-colorBgIconHover'
              onClick={() => onClickItem?.(item)}
            >
              <List.Item.Meta
                avatar={<Avatar size={48} fileId={item.avatar} name={item.fullName} />}
                title={
                  <Typography.Title level={5} className='m-0'>
                    {item.shortName}
                  </Typography.Title>
                }
                description={item.fullName}
              />
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  );
};

export default UserList;
