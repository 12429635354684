import OriginAvatar from './Avatar';
import AvatarUploadCrop from './UploadCrop';
import AvatarUploadOnlyCrop from './UploadOnly';

export type AvatarProps = typeof OriginAvatar & {
  UploadCrop: typeof AvatarUploadCrop;
  UploadOnlyCrop: typeof AvatarUploadOnlyCrop;
};
const Avatar = OriginAvatar as AvatarProps;

Avatar.UploadCrop = AvatarUploadCrop;
Avatar.UploadOnlyCrop = AvatarUploadOnlyCrop;

export { Avatar };
