import { List } from 'antd';
import { MessageDto } from 'types';
import VirtualList from 'rc-virtual-list';
import dayjs from 'dayjs';
import { useMessagesPaging } from 'hooks';
import { useGetConversationDetailQuery } from 'services';
import { twMerge } from 'tailwind-merge';
import { useSelector } from 'react-redux';
import MessageItem from './MessageItem';
import { useEffect, useRef, useState } from 'react';
import { selectUserProfile } from 'store/features';
import UserTyping from './UserTyping';

type MessageListProps = {
  conversationId: string;
};
const MessageList: React.FC<MessageListProps> = ({ conversationId }) => {
  const listContainerRef = useRef<any>(null);
  const listRef = useRef<any>(null);
  const isLoaded = useRef<boolean>(false);
  const { data: conversationDetailRes } = useGetConversationDetailQuery(conversationId);
  const { messages, handleChangePage, pageIndex, isLoading, hasMore } = useMessagesPaging({ conversationId });
  const [listHeight, setListHeight] = useState(0);

  const profile = useSelector(selectUserProfile);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollTop <= 20 && !isLoading && hasMore) {
      handleChangePage(pageIndex + 1);
    }
  };

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTo({ index: messages.length - 1 });
    }
  };

  const conversation = conversationDetailRes?.data;

  useEffect(() => {
    if (!messages || messages.length === 0) return;
    if (isLoaded.current === true) return;
    scrollToBottom();
    isLoaded.current = true;
  }, [messages]);

  useEffect(() => {
    if (!listContainerRef.current) return;
    setListHeight(listContainerRef.current.clientHeight);
  }, [listContainerRef.current]);

  return (
    <div ref={listContainerRef} className='h-[calc(100%-40px-44px)]'>
      <List split={false} size='small' className='h-full'>
        <VirtualList
          ref={listRef}
          itemKey='messageId'
          data={messages}
          height={listHeight}
          fullHeight
          onScroll={onScroll}
        >
          {(item: MessageDto, idx: number) => {
            const nextSenderId = messages[idx + 1]?.senderId;
            const sender =
              item.senderId !== nextSenderId
                ? conversation?.participants.find((participant) => participant.userId === item.senderId)
                : undefined;

            const previousMessage = messages[idx - 1];
            const isMessageOnSameDate = previousMessage
              ? dayjs(item.sentAt).isSame(previousMessage.sentAt, 'date')
              : false;

            return (
              <List.Item
                key={item.messageId}
                className={twMerge('px-0 py-[2px]', item.senderId === profile?.userId ? 'flex-row-reverse' : '')}
              >
                <div className='flex flex-col gap-1'>
                  <MessageItem data={item} showDate={!isMessageOnSameDate} sender={sender} />
                  {idx === messages.length - 1 && <UserTyping participants={conversation?.participants || []} />}
                </div>
              </List.Item>
            );
          }}
        </VirtualList>
      </List>
    </div>
  );
};

export default MessageList;
