import { Typography } from 'antd';
import { internalChatMessages } from 'messages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { offTyping, onTyping } from 'services';
import { selectUserProfile } from 'store/features';
import { ConversationParticiPantDto } from 'types';

type UserTypingProps = {
  participants: ConversationParticiPantDto[];
};

const UserTyping: React.FC<UserTypingProps> = ({ participants }) => {
  const [data, setData] = useState<ConversationParticiPantDto>();
  const profile = useSelector(selectUserProfile);

  useEffect(() => {
    onTyping((data) => {
      if (profile?.userId === data.userId) return;
      const user = participants.find((participant) => participant.userId === data.userId);
      setData(user);
    });

    return () => {
      offTyping();
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(undefined);
    }, 3_000);

    return () => {
      clearTimeout(timeout);
    };
  }, [data]);

  return (
    <div className='relative h-5'>
      {data && (
        <div className='position bottom-0 right-0 bg-colorBgBody px-1'>
          <Typography.Text className='text-sm'>
            {data.name} {internalChatMessages.isTyping.toLowerCase()}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default UserTyping;
