export const internalChatMessages = {
  keyword: 'Từ khoá',
  name: 'Tên đoạn chat',
  participants: 'Thành viên',
  adminId: 'Quản trị viên',
  createNewGroupConversation: 'Tạo mới nhóm chat',
  updateGroupConversation: 'Cập nhật nhóm chat',
  deleteGroup: 'Xóa nhóm chat',
  confirmDeleteGroup: 'Bạn có muốn xóa nhóm chat này?',
  isTyping: 'Đang soạn tin...'
};
