import { Spin, Typography } from 'antd';
import { ServerFileList } from 'components/common';
import dayjs from 'dayjs';
import { internalAnnouncementsMessages } from 'messages';
import { forwardRef, useImperativeHandle } from 'react';
import { InternalAnnouncementActiveDto } from 'types';
import { DATE_FORMAT } from 'utils';

export type InternalAnnouncementDetailActiveProps = {
  loading: boolean;
  data: InternalAnnouncementActiveDto;
};

export type InternalAnnouncementDetailActiveRefProps = {};

const InternalAnnouncementDetailActive = forwardRef<
  InternalAnnouncementDetailActiveRefProps,
  InternalAnnouncementDetailActiveProps
>(({ data, loading }, ref) => {
  useImperativeHandle(ref, () => ({}));

  return (
    <div className='min-h-[calc(100vh-80px)] p-5 pb-20'>
      <Spin spinning={loading}>
        {data && (
          <>
            <div>
              <Typography.Title level={3}>{data.title}</Typography.Title>
            </div>

            <Typography.Title level={5}>{internalAnnouncementsMessages.content}</Typography.Title>
            <div
              dangerouslySetInnerHTML={{
                __html: data.content
              }}
              className='mb-2'
            />
            <Typography.Title level={5}>{internalAnnouncementsMessages.files}</Typography.Title>
            {/* <div className='mb-2 flex items-center gap-2'>
              {data.files.map((file) => (
                <File.Server className='h-24 w-24' fileId={file} />
              ))}
            </div> */}
            <ServerFileList isPreview fileList={data.files} />
            <Typography.Title level={5}>{internalAnnouncementsMessages.organizationUnit}</Typography.Title>

            <Typography.Title level={5}>{internalAnnouncementsMessages.createdBy}</Typography.Title>
            <Typography.Text>{data.createdBy?.fullName}</Typography.Text>

            <Typography.Title level={5}>{internalAnnouncementsMessages.createdAt}</Typography.Title>
            <Typography.Text>{dayjs(data.createdAt).format(DATE_FORMAT)}</Typography.Text>

            <Typography.Title level={5}>{internalAnnouncementsMessages.updatedAt}</Typography.Title>
            <Typography.Text>{dayjs(data.updatedAt).format(DATE_FORMAT)}</Typography.Text>
          </>
        )}
      </Spin>
    </div>
  );
});
export default InternalAnnouncementDetailActive;
