import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateMessageDto,
  UpdateMessageDto,
  ConversationDto,
  ResponsePagingDto,
  ResponseSuccessDto,
  CreateGroupConversationDto,
  CreatePrivateConversationDto,
  MessageDto,
  UpdateGroupConversationDto,
  FindAllConversationDto
} from 'types';
import { FindAllMessageDto } from 'types/dto/internal-chat/find-all-message.dto';

import axiosBaseQuery from 'utils/base-api';
import { offReceiveMessage, onReceiveMessage } from './internal-chat.socket-client';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  tagTypes: ['conversations', 'conversation_detail', 'messages'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    // Create a new private conversation
    createPrivateConversation: builder.mutation<ResponseSuccessDto<ConversationDto>, CreatePrivateConversationDto>({
      query: ({ userId }) => ({
        url: `/internal_chat/private/${userId}`,
        method: 'post'
      }),
      invalidatesTags: ['conversations']
    }),

    // Create a new group conversation
    createGroupConversation: builder.mutation<ResponseSuccessDto<ConversationDto>, CreateGroupConversationDto>({
      query: (newGroup) => ({
        url: '/internal_chat/group',
        method: 'post',
        data: newGroup
      }),
      invalidatesTags: ['conversations']
    }),

    // Fetch all messages with pagination
    getMessages: builder.query<ResponsePagingDto<MessageDto>, FindAllMessageDto>({
      query: (params) => ({
        url: '/internal_chat/messages',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.data.count = newItems.data.count;
        currentCache.data.rows.unshift(...newItems.data.rows);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ messageId }) => ({
              type: 'messages',
              id: messageId
            }))
          : ['messages'],
      async onCacheEntryAdded(arg, { cacheDataLoaded, updateCachedData, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          onReceiveMessage((data) => {
            updateCachedData((draft) => {
              draft.data.count += 1;
              draft.data.rows.push(data);
            });
          });
        } catch (err) {
          console.log({ err });
        }

        await cacheEntryRemoved;
        offReceiveMessage();
      }
    }),

    // Fetch all conversations with pagination
    getConversations: builder.query<ResponsePagingDto<ConversationDto>, FindAllConversationDto>({
      query: (params) => ({
        url: '/internal_chat',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ conversationId }) => ({
              type: 'conversations',
              id: conversationId
            }))
          : ['conversations']
    }),

    // Fetch specific conversation details by conversationId
    getConversationDetail: builder.query<ResponseSuccessDto<ConversationDto>, string>({
      query: (conversationId) => ({
        url: `/internal_chat/${conversationId}`,
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'conversation_detail', id: result.data.conversationId }] : [])
    }),

    // Create a new message
    createMessage: builder.mutation<ResponseSuccessDto<MessageDto>, CreateMessageDto>({
      query: (newChat) => ({
        url: '/internal_chat/messages',
        method: 'post',
        data: newChat
      }),
      invalidatesTags: ['messages']
    }),

    // Update read message
    updateMessage: builder.mutation<ResponseSuccessDto<ConversationDto>, UpdateMessageDto>({
      query: ({ messageId }) => ({
        url: `/internal_chat/messages/${messageId}/read`,
        method: 'patch'
      })
    }),

    // Update group conversation with conversationId
    updateGroupConversation: builder.mutation<ResponseSuccessDto<ConversationDto>, UpdateGroupConversationDto>({
      query: ({ conversationId, ...updateGroupConversation }) => ({
        url: `/internal_chat/group/${conversationId}`,
        method: 'patch',
        data: updateGroupConversation
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'conversations', id: param.conversationId },
        { type: 'conversation_detail', id: param.conversationId }
      ]
    }),

    // Delete a chat group by conversationId
    deleteGroupConversation: builder.mutation<void, string>({
      query: (conversationId) => ({
        url: `/internal_chat/group/${conversationId}`,
        method: 'delete'
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'conversations', id: param },
        { type: 'conversation_detail', id: param }
      ]
    })
  })
});

export const {
  useCreatePrivateConversationMutation,
  useCreateGroupConversationMutation,
  useUpdateGroupConversationMutation,
  useDeleteGroupConversationMutation,
  useCreateMessageMutation,
  useGetMessagesQuery,
  useUpdateMessageMutation,
  useGetConversationsQuery,
  useGetConversationDetailQuery
} = chatApi;
