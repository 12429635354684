import { Form, FormInstance, Input, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { message } from 'components/common';
import { SelectOrganizationUnits } from 'components/organization-units';
import { SelectPermissions } from 'components/permissions';
import { SelectRolesOptions } from 'components/roles';
import { validateMessages } from 'messages';
import { userGroupsMessages } from 'messages/user-groups.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateUserGroupMutation,
  useGetUserGroupDetailQuery,
  useUpdateUserGroupMutation
} from 'services/user-groups';
import { CreateUserGroupDto } from 'types';
import { userGroupsValidationRules } from 'utils/validation-rules/user-groups.validation-rules';
export type UserGroupFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  userGroupId?: number;
};

export type UserGroupFormRefProps = {
  form: FormInstance<UserGroupFormType>;
  isLoading: boolean;
};

export type UserGroupFormType = Omit<CreateUserGroupDto, 'permissionIds' | 'organizationUnitId' | 'roleIds'> & {
  organizationUnitId: DefaultOptionType;
  permissionIds?: DefaultOptionType[];
  roleIds?: DefaultOptionType[];
};
const UserGroupForm = forwardRef<UserGroupFormRefProps, UserGroupFormProps>(
  ({ onChangeLoading, onCreateSuccess, userGroupId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const [form] = Form.useForm<UserGroupFormType>();

    const { data: userGroup, isLoading: isLoadingDetail } = useGetUserGroupDetailQuery(userGroupId!, {
      skip: !userGroupId,
      refetchOnMountOrArgChange: true
    });

    const [onCreate, { isLoading: isLoadingCreate }] = useCreateUserGroupMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserGroupMutation();

    useEffect(() => {
      if (userGroup && userGroupId) {
        form.setFieldsValue({
          ...userGroup.data,
          roleIds: userGroup.data.roles?.map((o) => ({ value: o.roleId, label: o.name })),
          permissionIds: userGroup.data.permissions?.map((o) => ({ value: o.permissionId, label: o.name })),
          organizationUnitId: userGroup.data.organizationUnitId
            ? {
                label: userGroup.data.organizationUnit?.name,
                value: userGroup.data.organizationUnitId
              }
            : undefined
        });
      }
    }, [userGroup, userGroupId]);

    const onFinish = ({ ...values }: UserGroupFormType) => {
      const data: CreateUserGroupDto = {
        ...values,
        organizationUnitId: values.organizationUnitId.value as number,
        permissionIds: values.permissionIds?.map((o) => o.value as number),
        roleIds: values.roleIds?.map((o) => o.value as number)
      };
      if (!userGroupId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          userGroupId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    return (
      <Form
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='userGroupForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
      >
        <Spin spinning={isLoadingDetail || isLoadingCreate || isLoadingUpdate}>
          <Form.Item<UserGroupFormType>
            label={userGroupsMessages.userGroupName}
            name='name'
            rules={userGroupsValidationRules.name}
          >
            <Input />
          </Form.Item>

          <Form.Item<UserGroupFormType>
            label={userGroupsMessages.organizationUnit}
            name='organizationUnitId'
            rules={userGroupsValidationRules.organizationUnitId}
          >
            <SelectOrganizationUnits labelInValue />
          </Form.Item>
          <Form.Item<UserGroupFormType>
            label={userGroupsMessages.permission}
            name='permissionIds'
            rules={userGroupsValidationRules.permissionIds}
          >
            <SelectPermissions labelInValue mode='multiple' />
          </Form.Item>
          <Form.Item<UserGroupFormType>
            label={userGroupsMessages.roles}
            name='roleIds'
            rules={userGroupsValidationRules.roleIds}
          >
            <SelectRolesOptions labelInValue mode='multiple' />
          </Form.Item>
        </Spin>
      </Form>
    );
  }
);
export default UserGroupForm;
