import { Rule } from 'antd/es/form';
import { UserFormType } from 'components';
import { rulesMessages } from 'messages';

export const userValidationRules: Partial<Record<keyof UserFormType, Rule[]>> = {
  fullName: [{ required: true }],
  shortName: [{ required: true }],
  genderId: [{ required: true }],
  username: [{ required: true }],
  password: [{ required: true }, { min: 6 }],
  confirmPassword: [
    { required: true },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(rulesMessages.passwordsNotMatch));
      }
    })
  ],
  email: [{ type: 'email' }, { required: true }],
  organizationUnitId: [{ required: true }],
  statusId: [{ required: true }]
};
