import * as XLSX from 'xlsx-js-style';

const style: XLSX.CellStyle = {
  font: {
    name: 'Times New Roman',
    sz: 16
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true
  }
};

export function createExcelBuffer(rows: any[][]): Buffer {
  const worksheet = XLSX.utils.aoa_to_sheet(rows, { cellStyles: true });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet);

  const numOfCols = Math.floor(Object.keys(worksheet).filter((key) => key[0] !== '!').length / 2);
  worksheet['!cols'] = Array(numOfCols).fill({ width: 30 });

  for (let cell in worksheet) {
    if (cell[0] === '!') {
      continue;
    }

    worksheet[cell].s = style;
  }

  const buffer = XLSX.write(workbook, { type: 'buffer', cellStyles: true });
  return buffer;
}
