import { Form, FormInstance, Input, InputNumber, Space, Spin, Switch, Tree, TreeProps, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FormItem, message } from 'components';
import { messages, validateMessages } from 'messages';
import { serviceTypesMessages } from 'messages/service-types.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateServiceTypeMutation, useGetServiceTypeDetailQuery, useUpdateServiceTypeMutation } from 'services';
import { CreateServiceTypeDto, ServiceTypeFormDto } from 'types';
import {
  createServiceTypeInitialValues,
  SERVICE_TYPES_FORM_OPTIONS,
  serviceTypeValidationRules,
  updateServiceTypeInitialValues
} from 'utils';
import SelectServiceTypes from './SelectServiceTypes';

export type ServiceTypeFromProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  serviceTypeId?: number;
};

export type ServiceTypeFromRefProps = {
  form: FormInstance<ServiceTypeFormType>;
  isLoading: boolean;
};

export type ServiceTypeFormType = Omit<CreateServiceTypeDto, 'parentId'> & {
  /** Danh sách yêu cầu lập phiếu */
  formKeyArr: string[];
  parentId?: DefaultOptionType;
};
const ServiceTypeForm = forwardRef<ServiceTypeFromRefProps, ServiceTypeFromProps>(
  ({ onChangeLoading, onCreateSuccess, serviceTypeId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const { data: serviceType, isLoading: isLoadingDetail } = useGetServiceTypeDetailQuery(serviceTypeId!, {
      skip: !serviceTypeId,
      refetchOnMountOrArgChange: true
    });

    useEffect(() => {
      if (serviceType && serviceTypeId) {
        const formKeyArr = Object.keys(serviceType.data.form).filter(
          (key) => serviceType.data.form[key as keyof ServiceTypeFormDto] === true
        );

        form.setFieldsValue({
          ...serviceType.data,
          parentId: serviceType.data.parentId
            ? {
                label: serviceType.data.parent?.name,
                value: serviceType.data.parentId
              }
            : undefined,
          formKeyArr
        });
      }
    }, [serviceType, serviceTypeId]);

    const [form] = Form.useForm<ServiceTypeFormType>();

    const [onCreate, { isLoading: isLoadingCreate }] = useCreateServiceTypeMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateServiceTypeMutation();

    const onFinish = ({ formKeyArr, ...values }: ServiceTypeFormType) => {
      const formKey = formKeyArr.filter((value) => !SERVICE_TYPES_FORM_OPTIONS.map((o) => o.value).includes(value));
      const servicesTypesOrigin = SERVICE_TYPES_FORM_OPTIONS.flatMap((o) => o.options.map((o) => o.value));

      const form = servicesTypesOrigin.reduce((acc: Record<string, boolean>, value: string) => {
        if (formKey.includes(value)) acc[value] = true;
        else acc[value] = false;
        return acc;
      }, {});

      const data: CreateServiceTypeDto = {
        ...values,
        parentId: values.parentId?.value as number,
        form
      };

      if (!serviceTypeId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          serviceTypeId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
      form.setFieldValue('formKeyArr', checkedKeysValue);
    };

    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='serviceTypeForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={serviceTypeId ? updateServiceTypeInitialValues : createServiceTypeInitialValues}
      >
        <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
          <FormItem.FloatLabel<ServiceTypeFormType>
            name='code'
            label={serviceTypesMessages.code}
            rules={serviceTypeValidationRules.code}
          >
            <Input />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<ServiceTypeFormType>
            name='name'
            label={serviceTypesMessages.name}
            rules={serviceTypeValidationRules.name}
          >
            <Input />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<ServiceTypeFormType>
            name='parentId'
            label={serviceTypesMessages.parent}
            rules={serviceTypeValidationRules.parentId}
          >
            <SelectServiceTypes labelInValue />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<ServiceTypeFormType>
            name='priority'
            label={serviceTypesMessages.priority}
            rules={serviceTypeValidationRules.priority}
          >
            <InputNumber min={1} className='w-1/4' />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<ServiceTypeFormType>
            name='processingDeadline'
            label={serviceTypesMessages.processingDeadline}
            rules={serviceTypeValidationRules.processingDeadline}
          >
            <InputNumber min={1} className='w-1/4' />
          </FormItem.FloatLabel>

          <Typography.Title level={5} className='mb-6 mt-6'>
            {serviceTypesMessages.settingRequestForm}
          </Typography.Title>
          <Form.Item<ServiceTypeFormType>
            name='formKeyArr'
            valuePropName='checkedKeys'
            rules={serviceTypeValidationRules.formKeyArr}
          >
            <Tree
              defaultExpandAll
              checkable
              treeData={SERVICE_TYPES_FORM_OPTIONS.map((o) => ({
                title: o.groupLabel,
                key: o.value,
                children: o.options.map((option) => ({
                  title: option.label,
                  key: option.value
                }))
              }))}
              onCheck={onCheck}
              selectable={false}
            />
          </Form.Item>
          <Space align='center'>
            <Form.Item<ServiceTypeFormType>
              noStyle
              name='isActive'
              valuePropName='checked'
              rules={serviceTypeValidationRules.isActive}
            >
              <Switch />
            </Form.Item>
            <Typography.Text>{messages.statusEnum.active}</Typography.Text>
          </Space>
        </Spin>
      </Form>
    );
  }
);
export default ServiceTypeForm;
