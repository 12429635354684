import { Checkbox, Form, FormInstance, Input, Skeleton } from 'antd';
import { Rule } from 'antd/es/form';
import { DefaultOptionType } from 'antd/es/select';
import { message } from 'components/common';
import SelectServiceTypes from 'components/service-types/SelectServiceTypes';
import { validateMessages } from 'messages';
import { organizationUnitsMessages } from 'messages/organization-units.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useAddOrganizationUnitMutation,
  useGetOrganizationUnitDetailQuery,
  useUpdateOrganizationUnitMutation
} from 'services/organization-units';
import { CreateOrganizationUnitDto } from 'types';
import {
  createOrganizationUnitInitialValues,
  organizationUnitValidationRules,
  updateOrganizationUnitInitialValues
} from 'utils';
import SelectOrganizationUnits from './SelectOrganizationUnits';
export type OrganizationUnitFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  organizationUnitId?: number;
};

export type OrganizationUnitFormRefProps = {
  form: FormInstance<OrganizationUnitsFormType>;
  isLoading: boolean;
};

export type OrganizationUnitsFormType = Omit<CreateOrganizationUnitDto, 'parentId' | 'serviceTypeIds'> & {
  isHasParent?: boolean;
  parentId?: DefaultOptionType;
  serviceTypeIds: DefaultOptionType[];
};
const OrganizationUnitForm = forwardRef<OrganizationUnitFormRefProps, OrganizationUnitFormProps>(
  ({ onChangeLoading, onCreateSuccess, organizationUnitId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const [form] = Form.useForm<OrganizationUnitsFormType>();

    const { data: organizationUnit, isLoading: isLoadingDetail } = useGetOrganizationUnitDetailQuery(
      organizationUnitId!,
      {
        skip: !organizationUnitId,
        refetchOnMountOrArgChange: true
      }
    );

    const [onCreate, { isLoading: isLoadingCreate }] = useAddOrganizationUnitMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateOrganizationUnitMutation();

    useEffect(() => {
      if (organizationUnit && organizationUnitId) {
        form.setFieldsValue({
          ...organizationUnit.data,
          isHasParent: !!organizationUnit.data.parentId,
          parentId: organizationUnit.data?.parentId
            ? {
                label: organizationUnit.data?.parent?.name,
                value: organizationUnit.data.parentId
              }
            : undefined,
          serviceTypeIds: organizationUnit.data.serviceTypes
            ? organizationUnit.data.serviceTypes.map((service) => ({
                label: service?.name,
                value: service.serviceTypeId
              }))
            : []
        });
      }
    }, [organizationUnit, organizationUnitId]);

    const onFinish = ({ isHasParent, ...values }: OrganizationUnitsFormType) => {
      const data: CreateOrganizationUnitDto = {
        ...values,
        serviceTypeIds: values?.serviceTypeIds?.map((o) => o.value as number),
        parentId: isHasParent ? (values.parentId?.value as number) : undefined
      };
      if (!organizationUnitId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          organizationUnitId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    const isHasParent = Form.useWatch('isHasParent', form);

    const validationRules: Partial<Record<keyof OrganizationUnitsFormType, Rule[]>> = {
      ...organizationUnitValidationRules,
      parentId: [{ required: isHasParent }]
    };
    return (
      <Form
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='organizationUnitForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={organizationUnitId ? updateOrganizationUnitInitialValues : createOrganizationUnitInitialValues}
      >
        <Skeleton loading={isLoadingDetail}>
          <Form.Item<OrganizationUnitsFormType>
            label={<div />}
            name='isHasParent'
            valuePropName='checked'
            rules={validationRules.isHasParent}
          >
            <Checkbox>{organizationUnitsMessages.hasParent}</Checkbox>
          </Form.Item>
          <Form.Item<OrganizationUnitsFormType>
            label={organizationUnitsMessages.upperOrganizationUnit}
            rules={validationRules.parentId}
            name='parentId'
          >
            <SelectOrganizationUnits disabled={!isHasParent} labelInValue />
          </Form.Item>
          <Form.Item<OrganizationUnitsFormType>
            rules={validationRules.code}
            label={organizationUnitsMessages.organizationUnitId}
            name='code'
          >
            <Input />
          </Form.Item>
          <Form.Item<OrganizationUnitsFormType>
            rules={validationRules.name}
            label={organizationUnitsMessages.organizationUnitName}
            name='name'
          >
            <Input />
          </Form.Item>

          <Form.Item<OrganizationUnitsFormType>
            rules={validationRules.serviceTypeIds}
            label={organizationUnitsMessages.service}
            name='serviceTypeIds'
          >
            <SelectServiceTypes isActive labelInValue mode='multiple' />
          </Form.Item>
        </Skeleton>
      </Form>
    );
  }
);
export default OrganizationUnitForm;
