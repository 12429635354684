import { SmileOutlined } from '@ant-design/icons';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Button, Popover } from 'antd';
import React from 'react';
import { EmojiProps } from 'types';
type ChatEmojiProps = {
  handleSelect: (value: EmojiProps) => void;
};

const ChatEmoji: React.FC<ChatEmojiProps> = ({ handleSelect }) => {
  return (
    <Popover
      prefixCls='emoji-popover'
      trigger={['click']}
      placement='topRight'
      title={null}
      content={<Picker onEmojiSelect={handleSelect} theme='light' previewPosition='none' locale='vi' data={data} />}
    >
      <Button type='text' className='mt-auto h-8 w-8 min-w-0' shape='circle' icon={<SmileOutlined />} />
    </Popover>
  );
};

export default ChatEmoji;
