import { FileOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { find } from 'lodash';
import React from 'react';
import { colorsBase } from 'themes';
import { convertFileType, FILE_SYMBOL } from 'utils';

type OtherFileProps = {
  type?: string;
  name?: string;
};
const OtherFile: React.FC<OtherFileProps> = ({ type, name }) => {
  const fileSymbol = find(FILE_SYMBOL, {
    value: convertFileType(type)
  });
  return (
    <div className='flex h-full items-center gap-2'>
      <div
        className='flex h-8 w-8 items-center justify-center rounded text-white'
        style={{
          background: fileSymbol?.color || colorsBase.colorPrimary
        }}
      >
        {fileSymbol?.icon || <FileOutlined className='text-white' />}
      </div>
      <div className='flex max-w-36 flex-col text-left'>
        <Typography.Text className='text-sm font-semibold' ellipsis>
          {name ?? '-'}
        </Typography.Text>
        <Typography.Text className='text-xs' ellipsis>
          {type ?? '-'}
        </Typography.Text>
      </div>
    </div>
  );
};
export default OtherFile;
