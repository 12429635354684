import { Form, FormInstance, Input, Skeleton } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FormItem, message } from 'components/common';
import { SelectPermissions } from 'components/permissions';
import { rolesMessages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateRoleMutation, useGetRoleDetailQuery, useUpdateRoleMutation } from 'services';
import { CreateRoleDto } from 'types';
import { createRoleInitialValues, roleValidationRules, updateRoleInitialValues } from 'utils';
export type RoleFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  roleId?: number;
};

export type RoleFormRefProps = {
  form: FormInstance<RoleFormType>;
  isLoading: boolean;
};

export type RoleFormType = Omit<CreateRoleDto, 'permissionIds'> & {
  isHasParent?: boolean;
  permissionIds: DefaultOptionType[];
};
const RoleForm = forwardRef<RoleFormRefProps, RoleFormProps>(({ onChangeLoading, onCreateSuccess, roleId }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));

  const [form] = Form.useForm<RoleFormType>();

  const { data: role, isLoading: isLoadingDetail } = useGetRoleDetailQuery(roleId!, {
    skip: !roleId,
    refetchOnMountOrArgChange: true
  });

  const [onCreate, { isLoading: isLoadingCreate }] = useCreateRoleMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateRoleMutation();

  useEffect(() => {
    if (role && roleId) {
      form.setFieldsValue({
        ...role.data,
        permissionIds: role.data?.permissions?.map((o) => ({
          value: o.permissionId,
          label: o.name
        }))
      });
    }
  }, [role, roleId]);

  const onFinish = ({ isHasParent, ...values }: RoleFormType) => {
    const data: CreateRoleDto = {
      ...values,
      permissionIds: values.permissionIds.map((o) => o.value as number)
    };
    if (!roleId) {
      onCreate(data)
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onCreateSuccess?.();
        });
    } else {
      onUpdate({
        roleId,
        ...data
      })
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onCreateSuccess?.();
        });
    }
  };
  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingCreate || isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

  return (
    <Form
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      requiredMark={false}
      form={form}
      name='roleForm'
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
      initialValues={roleId ? updateRoleInitialValues : createRoleInitialValues}
    >
      <Skeleton loading={isLoadingDetail}>
        <FormItem.FloatLabel<RoleFormType> label={rolesMessages.name} name='name' rules={roleValidationRules.name}>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<RoleFormType> label={rolesMessages.code} name='code' rules={roleValidationRules.code}>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<RoleFormType>
          label={rolesMessages.description}
          name='description'
          rules={roleValidationRules.description}
        >
          <Input.TextArea />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<RoleFormType>
          name='permissionIds'
          label={rolesMessages.permissions}
          rules={roleValidationRules.permissionIds}
        >
          <SelectPermissions mode='multiple' labelInValue />
        </FormItem.FloatLabel>
      </Skeleton>
    </Form>
  );
});
export default RoleForm;
