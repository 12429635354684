import { Button, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { AttachIcon } from 'assets';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import React from 'react';
import { FileUpload } from 'types';

type ChatAttachProps = {
  attachFile: (val: FileUpload) => void;
  beforeUpload: (val: FileUpload) => boolean;
};

const ChatAttach: React.FC<ChatAttachProps> = ({ attachFile, beforeUpload }) => {
  const customRequest = async (options: RcCustomRequestOptions) => {
    const { file } = options;
    attachFile(file as RcFile);
  };

  return (
    <Upload
      beforeUpload={beforeUpload}
      fileList={[]}
      itemRender={() => null}
      name='file-attach'
      customRequest={customRequest}
    >
      <Button type='text' className='mt-auto h-8 w-8 min-w-0' shape='circle' icon={<AttachIcon />} />
    </Upload>
  );
};

export default ChatAttach;
