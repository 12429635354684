import { Upload as UploadAntd } from 'antd';
import { UploadFile, UploadProps as UploadPropsAntd } from 'antd/es/upload';
import { FileDto } from 'types';
import { File } from '../files';
import { isServerUploadFile, ServerFileWithUid } from './ServerUpload';

type UploadProps = UploadPropsAntd & {
  onUploadSuccess?: (value: FileDto) => void;
};

const Upload = ({ onUploadSuccess, ...props }: UploadProps) => {
  const fileList = props.fileList
    ? Array.isArray(props.fileList)
      ? (props.fileList as UploadFile[])
      : [props.fileList]
    : undefined;

  const handleBeforeUpload = (file: any) => {
    /** Trả về false để ngăn gọi API */
    return false;
  };
  return (
    <UploadAntd
      {...props}
      prefixCls='custom-upload'
      beforeUpload={handleBeforeUpload}
      fileList={fileList || undefined}
      itemRender={(
        _origin: React.ReactElement,
        file: UploadFile | ServerFileWithUid,
        _fileList: Array<UploadFile | number>,
        actions
      ) => {
        if (isServerUploadFile(file))
          return (
            <File.Server
              className='h-full w-full'
              fileId={file.fileId}
              remove={() => {
                actions.remove();
              }}
            />
          );
        if (file.originFileObj) {
          const url = URL.createObjectURL(file.originFileObj!);
          return <File url={url} type={file.type} key={file.uid} name={file.name} remove={() => actions.remove()} />;
        }
      }}
    />
  );
};

export default Upload;
