import dayjs from 'dayjs';
import { messages } from 'messages';
import { DATE_TIME_FORMAT, WEEKDAY_FORMAT } from './constants';
import { FILE_TYPE } from './enums';

export function capitalizeFirstLetter(text: string = '') {
  if (!text) {
    return text;
  }

  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function removeSpecialCharacters(keyword: string): string {
  if (!keyword) {
    return '';
  }

  return keyword
    .toLowerCase()
    .replace('đ', 'd')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\\s+/g, ' ')
    .trim();
}

export function formatNumber(number: number, locale?: string): string {
  return new Intl.NumberFormat(locale ?? 'en-US').format(number);
}

export const stringToHslColor = (str?: string, s?: number, l?: number) => {
  if (!str) return '#000000';
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const disabledDateUnderYear =
  (year = 18) =>
  (current: dayjs.Dayjs | null) => {
    const dayYearsAgo = dayjs().subtract(year, 'year');
    return !!current && current.isAfter(dayYearsAgo);
  };

export const formatWeekDate = (date: dayjs.Dayjs): string => {
  const today = dayjs();

  if (today.isSame(date, 'day')) {
    return messages.today;
  }

  return date.format(WEEKDAY_FORMAT);
};

export const removeEmptyChildren = (data: any[]) => {
  return data.map((itm) => {
    if (itm.children && itm.children.length === 0) {
      delete itm.children;
    } else if (itm.children) {
      itm.children = removeEmptyChildren(itm.children);
    }
    return itm;
  });
};

export const checkPositionCursor = (e: React.KeyboardEvent<HTMLDivElement>, target: HTMLDivElement) => {
  const range = window.getSelection()?.getRangeAt(0);
  const preRange = document.createRange();
  preRange.selectNodeContents(target);
  if (range) {
    preRange.setEnd(range.startContainer, range.startOffset);
    const text = preRange.cloneContents();
    const atStart = text?.textContent?.length === 0;
    const postRange = document.createRange();
    postRange.selectNodeContents(target);
    postRange.setStart(range.endContainer, range.endOffset);
    const nextText = postRange.cloneContents();
    const atEnd = nextText?.textContent?.length === 0;
    return { atEnd, atStart };
  }
  return {
    atStart: false,
    atEnd: false
  };
};

/** Thêm văn bản vào vị trí con trỏ hiện tại mà không giữ bất kỳ định dạng nào bằng cách sử dụng Range API */
export const execCommand = (text: string) => {
  const selection = window.getSelection();
  if (!selection || !selection.rangeCount) return;

  const range = selection.getRangeAt(0);
  range.deleteContents();
  const textNode = document.createTextNode(text);
  range.insertNode(textNode);

  /** Di chuyển con trỏ đến cuối văn bản mới chèn  */
  range.setStartAfter(textNode);
  selection.removeAllRanges();
  selection.addRange(range);
};

export const convertFileType = (type?: string) => {
  if (!type) return FILE_TYPE.UNKNOWN;

  if (type.startsWith('image/')) return FILE_TYPE.IMAGE;
  if (
    type === 'application/vnd.ms-excel' || // .xls
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
  ) {
    return FILE_TYPE.EXCEL;
  }
  if (type.endsWith('pdf')) {
    return FILE_TYPE.PDF;
  }
  if (type.endsWith('mp4')) {
    return FILE_TYPE.VIDEO;
  }
  return FILE_TYPE.UNKNOWN;
};

export const moveCaretToEnd = (element: HTMLElement) => {
  // Tạo một đối tượng range và selection
  const range = document.createRange();
  const selection = window.getSelection();

  // Đặt range đến cuối nội dung
  range.selectNodeContents(element);
  range.collapse(false); // Di chuyển con trỏ đến cuối

  // Xóa mọi range hiện tại và đặt range mới
  selection?.removeAllRanges();
  selection?.addRange(range);

  // Đảm bảo focus vào ô contenteditable
  element.focus();
};

const TIME_SPAN = {
  day_5: 7200,
  day_1: 1440,
  hour: 60,
  minute: 3
};
export const timeSpanDes = (date: Date | string): string => {
  const minute = dayjs().diff(date, 'minute');
  if (minute > TIME_SPAN.day_5) {
    return dayjs(date).format(DATE_TIME_FORMAT);
  }
  if (minute > TIME_SPAN.day_1) {
    return dayjs().diff(date, 'day') + ' ngày trước';
  }
  if (minute > TIME_SPAN.hour) {
    return dayjs().diff(date, 'hour') + ' giờ trước';
  }
  if (minute > TIME_SPAN.minute) {
    return minute + ' phút trước';
  }
  return 'Vừa xong';
};

export function isValidJson(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (err) {
    return false;
  }
}
