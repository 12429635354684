export enum CHAT_SOCKET_EVENT {
  JOIN_CONVERSATION = 'join_conversation',
  TYPING = 'typing',
  SEND_MESSAGE = 'send_message',
  USER_TYPING = 'user_typing',
  RECEIVE_MESSAGE = 'receive_message',
  UNAUTHORIZED = 'unauthorized',
  CONNECT_ERROR = 'connect_error',
  OUT_CONVERSATION = 'out_conversation',
  CONNECT = 'connect'
}
