import { Button, Dropdown, List, MenuProps, Modal, Spin, Typography } from 'antd';
import { Avatar, InputSearch } from 'components/common';
import { useConversationsPaging } from 'hooks';
import { internalChatMessages, messages } from 'messages';
import { ConversationDto } from 'types';
import VirtualList from 'rc-virtual-list';
import { useState } from 'react';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useCreateGroupConversationMutation,
  useDeleteGroupConversationMutation,
  useUpdateGroupConversationMutation
} from 'services';
import { CONVERSATION_TYPE } from 'utils';
import CreateGroupConversationModal from './CreateGroupConversationModal';
import UpdateGroupConversationModal from './UpdateGroupConversationModal';
const { confirm } = Modal;

type ConversationListProps = {
  onClickItem?: (value?: ConversationDto) => void;
};

const ConversationList: React.FC<ConversationListProps> = ({ onClickItem }) => {
  const [keyword, setKeyword] = useState('');

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedConversationId, setSeletedConversationId] = useState<string>();

  const { conversations, pageIndex, handleChangePage, isLoading } = useConversationsPaging({
    keyword
  });

  const [createGroupConversation, { isLoading: isLoadingCreateGroup }] = useCreateGroupConversationMutation();
  const [updateGroupConversation, { isLoading: isLoadingUpdateGroup }] = useUpdateGroupConversationMutation();
  const [deleteGroupConversation, { isLoading: isLoadingDeleteGroup }] = useDeleteGroupConversationMutation();

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) <= 1) {
      handleChangePage(pageIndex + 1);
    }
  };

  const handleDelete = (record: ConversationDto) => {
    confirm({
      title: `${internalChatMessages.deleteGroup} ${record.name}`,
      content: internalChatMessages.confirmDeleteGroup,
      cancelText: messages.cancelButtonText,
      okText: messages.confirmButtonText,
      onOk: () => {
        deleteGroupConversation(record.conversationId);
        onClickItem?.(undefined);
      },
      okButtonProps: {
        loading: isLoadingDeleteGroup
      }
    });
  };

  const actionItems = (item: ConversationDto): MenuProps['items'] => [
    {
      key: 'edit',
      label: 'Cập nhật',
      onClick: () => setSeletedConversationId(item.conversationId)
    },
    {
      key: 'delete',
      label: 'Xóa',
      danger: true,
      onClick: () => handleDelete(item)
    }
  ];

  return (
    <div className='flex flex-col p-5'>
      <div className='flex justify-between'>
        <Typography.Title level={3}>Đoạn chat</Typography.Title>
        <Button shape='circle' type='link' icon={<PlusOutlined />} onClick={() => setOpenCreateModal(true)} />
      </div>
      <InputSearch placeholder={internalChatMessages.keyword} onChange={(e) => handleSearch(e.toString())} />

      <Spin spinning={isLoading}>
        <List>
          <VirtualList itemKey='conversationId' data={conversations} fullHeight onScroll={onScroll}>
            {(item: ConversationDto) => (
              <List.Item
                key={item.conversationId}
                className='cursor-pointer hover:bg-colorBgIconHover'
                onClick={() => onClickItem?.(item)}
                actions={
                  item.type === CONVERSATION_TYPE.GROUP
                    ? [
                        <Dropdown trigger={['click']} menu={{ items: actionItems(item) }}>
                          <Button
                            onClick={(e) => e.stopPropagation()}
                            shape='circle'
                            type='link'
                            icon={<EllipsisOutlined />}
                          />
                        </Dropdown>
                      ]
                    : []
                }
              >
                <List.Item.Meta
                  avatar={<Avatar size={48} fileId={item.image} name={item.name} />}
                  title={<Typography.Title level={5}>{item.name}</Typography.Title>}
                  description={<Typography.Text>{item.lastMessage?.content || ''}</Typography.Text>}
                />
              </List.Item>
            )}
          </VirtualList>
        </List>
      </Spin>
      <CreateGroupConversationModal
        open={openCreateModal}
        onCancel={() => setOpenCreateModal(false)}
        onSubmit={(data) => {
          createGroupConversation(data).then(() => {
            setOpenCreateModal(false);
          });
        }}
        confirmLoading={isLoadingCreateGroup}
      />
      {selectedConversationId && (
        <UpdateGroupConversationModal
          conversationId={selectedConversationId}
          open={!!selectedConversationId}
          onCancel={() => setSeletedConversationId(undefined)}
          onSubmit={(data) => {
            updateGroupConversation(data).then(() => {
              setSeletedConversationId(undefined);
            });
          }}
          confirmLoading={isLoadingUpdateGroup}
        />
      )}
    </div>
  );
};

export default ConversationList;
