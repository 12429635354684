import { GetProp, Upload as UploadAntd } from 'antd';
import { UploadFile, UploadProps as UploadPropsAntd } from 'antd/es/upload';
import type { UploadRequestOption as RcCustomRequestOptions, UploadRequestError } from 'rc-upload/lib/interface';
import React from 'react';
import { useDeleteFileMutation, useUploadFileMutation } from 'services';
import { FileDto } from 'types';
import { FilesBucketNames } from 'utils';
import { File } from '../files';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type UploadProps = UploadPropsAntd & {
  bucketName: FilesBucketNames;
  onUploadSuccess?: (value: FileDto) => void;
};
export type ServerFileWithUid = {
  uid: string | number;
  fileId: number;
};
export type ServerUploadFile = UploadFile<FileDto> | ServerFileWithUid;
export function isServerUploadFile(item: any): item is ServerFileWithUid {
  return (typeof item.uid === 'string' || typeof item.uid === 'number') && typeof item.fileId === 'number';
}

const Upload = ({ bucketName, onUploadSuccess, ...props }: UploadProps) => {
  const arrFile = props.fileList
    ? Array.isArray(props.fileList)
      ? (props.fileList as UploadFile<FileDto>[])
      : [props.fileList]
    : undefined;
  const fileList: any[] | undefined = arrFile?.map((file, idx) =>
    typeof file === 'number'
      ? {
          uid: `file-${idx}`,
          response: { fileId: file }
        }
      : {
          ...file
        }
  );
  const [onUpload] = useUploadFileMutation();
  const [onDelete] = useDeleteFileMutation();

  const customRequest = async (options: RcCustomRequestOptions) => {
    const { onError, onSuccess, onProgress, file } = options;
    try {
      onUpload({
        file,
        bucketName,
        onUploadProgress: (progress) => {
          onProgress?.({
            percent: progress?.progress ?? 0 * 100
          });
        }
      })
        .unwrap()
        .then((rs) => {
          onSuccess?.(rs.data);
        });
    } catch (error) {
      onError?.(error as UploadRequestError);
    }
  };
  const onRemove = async (file: UploadFile<FileDto> | number) => {
    const fileId = typeof file === 'number' ? file : file.response?.fileId;
    if (fileId) {
      await onDelete(fileId)
        .unwrap()
        .then((rs) => {
          return true;
        });
    }
    return true;
  };

  const onPreview = async (file: UploadFile<FileDto>) => {
    let src = file.response?.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <UploadAntd
      prefixCls='server-upload'
      onRemove={onRemove}
      onPreview={onPreview}
      itemRender={(
        _origin: React.ReactElement,
        file: ServerUploadFile,
        _fileList: UploadFile<FileDto>[] | number[],
        actions
      ) => {
        if (isServerUploadFile(file))
          return (
            <File.Server
              className='h-full w-full'
              fileId={file.fileId}
              remove={() => {
                actions.remove();
              }}
            />
          );
        return (
          <File.Server
            className='h-full w-full'
            fileId={file.response?.fileId}
            percent={file.percent}
            isError={file.status === 'error'}
            remove={() => {
              actions.remove();
            }}
          />
        );
      }}
      {...props}
      fileList={fileList || undefined}
      customRequest={customRequest}
    />
  );
};

export default Upload;
