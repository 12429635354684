import { messages } from 'messages';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FileUpload } from 'types';
import { checkPositionCursor, execCommand } from 'utils';

type ChatInputProps = {
  message: string;
  setMessage: (value: string) => void;
  handleSend: () => void;
  attachFile: (file: FileUpload) => void;
  beforeUpload: (file: FileUpload) => boolean;
};
export type ChatInputRefProps = {
  input: HTMLDivElement | null;
};
const ChatInput = forwardRef<ChatInputRefProps, ChatInputProps>(
  ({ handleSend, message, setMessage, attachFile, beforeUpload }, ref) => {
    const fakeInputRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (message.length > 0) {
        placeholderRef.current?.classList.add('hidden');
      } else {
        placeholderRef.current?.classList.remove('hidden');
      }
    }, [message]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (e.shiftKey && fakeInputRef.current) {
          const { atEnd } = checkPositionCursor(e, fakeInputRef.current);
          /** Shift enter xuống dòng, tuy nhiên div sẽ không nhận br ở dòng cuối cùng nên nếu ở đòng cuối sẽ insert thêm 1 lần nữa*/
          if (atEnd) {
            execCommand(`\n`);
          }
          execCommand(`\n`);
        } else {
          handleSend();
          if (fakeInputRef.current) {
            fakeInputRef.current.innerHTML = '';
          }
        }
      }
    };

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
      setMessage(e.currentTarget.textContent || '');
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      const clipboardItems = e.clipboardData.items;
      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];

        if (item.kind === 'file') {
          const blob = item.getAsFile();

          if (blob && beforeUpload(blob)) {
            attachFile(blob);
            return;
          }

          return;
        }
      }
      /** Lấy plain text từ clipboard */
      const text = e.clipboardData.getData('text/plain');
      execCommand(text);
      setMessage(fakeInputRef.current?.innerText || '');
    };

    useImperativeHandle(ref, () => ({
      input: fakeInputRef.current
    }));

    return (
      <div className='relative w-full'>
        <div
          id='chat-input-fake'
          ref={fakeInputRef}
          contentEditable
          className='max-h-[200px] w-full overflow-y-auto whitespace-pre-wrap break-words pr-[11px] text-left outline-none'
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
        />
        <div
          id='placeholder'
          ref={placeholderRef}
          className='pointer-events-none absolute top-0 text-colorTextPlaceholder'
        >
          {messages.placeholderChatBox}
        </div>
      </div>
    );
  }
);

export default ChatInput;
