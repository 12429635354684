import ServerUpload from './ServerUpload';
import OriginUpload from './Upload';

export type UploadProps = typeof OriginUpload & {
  ServerUpload: typeof ServerUpload;
};
const Upload = OriginUpload as UploadProps;
Upload.ServerUpload = ServerUpload;

export { Upload };
