import { Button, Form, FormInstance, List, Spin, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Empty } from 'components/common';
import Avatar from 'components/common/avatar/Avatar';
import { FormItem } from 'components/common/form-item';
import { SelectUsers } from 'components/users';
import { messages, usersMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useGetUserGroupDetailQuery, useUpdateUserGroupMutation } from 'services';
import { UserCompactDto } from 'types';

export type UserGroupUpdateUsersFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onChangeSuccess?: () => void;
  userGroupId: number;
};

export type UserGroupUpdateUsersFormRefProps = {
  form: FormInstance<UserGroupUpdateUsersFormType>;
  isLoading: boolean;
};
export type UserGroupUpdateUsersFormType = {
  users: UserCompactDto[];
};
const UserGroupUpdateUsersForm = forwardRef<UserGroupUpdateUsersFormRefProps, UserGroupUpdateUsersFormProps>(
  ({ userGroupId, onChangeLoading, onChangeSuccess }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const { data: userGroupRes, isLoading } = useGetUserGroupDetailQuery(userGroupId!, {
      skip: !userGroupId
    });
    const userGroup = userGroupRes?.data;

    const [form] = Form.useForm<UserGroupUpdateUsersFormType>();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserGroupMutation();
    const onFinish = (values: UserGroupUpdateUsersFormType) => {
      onUpdate({
        userGroupId,
        userIds: values.users.map((o) => o.userId)
      })
        .unwrap()
        .then(() => {
          onChangeSuccess?.();
        });
    };
    useEffect(() => {
      if (userGroup && userGroupId) {
        form.setFieldsValue({
          users: userGroup.users
        });
      }
    }, [userGroup?.users, userGroupId]);

    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingUpdate]);

    const users: UserCompactDto[] = Form.useWatch('users', form);
    const handleRemove = (record: UserCompactDto) => {
      form.setFieldValue(
        'users',
        users.filter((o) => o.userId !== record.userId)
      );
    };

    const handleAdd = (record: DefaultOptionType & UserCompactDto) => {
      form.setFieldValue('users', [
        {
          userId: record.userId,
          fullName: record.fullName,
          shortName: record.shortName,
          avatar: record.avatar
        },
        ...users.filter((o) => o.userId !== record.userId)
      ]);
    };
    return (
      <Form
        labelAlign='right'
        labelCol={{
          flex: '150px'
        }}
        requiredMark={false}
        form={form}
        name='userGroupForm'
        onFinish={onFinish}
        layout='horizontal'
      >
        <Spin spinning={isLoading}>
          <FormItem<UserGroupUpdateUsersFormType> name='users' noStyle />
          <SelectUsers
            onSelect={(_value, record) => {
              const item = record as DefaultOptionType & UserCompactDto;
              handleAdd(item);
            }}
            placeholder={usersMessages.fullName}
            size='large'
            className='mb-6 w-full'
            value={null}
          />
          <List
            itemLayout='horizontal'
            dataSource={users}
            rowKey={(item) => item.userId!}
            locale={{
              emptyText: <Empty />
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    danger
                    type='text'
                    size='small'
                    onClick={() => {
                      handleRemove(item);
                    }}
                    className='text-sm'
                  >
                    {messages.deleteButtonText}
                  </Button>
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar size={40} name={item.shortName} fileId={item.avatar} />}
                  title={<Typography.Text>{item.shortName}</Typography.Text>}
                  description={<Typography.Text>{item.fullName}</Typography.Text>}
                />
              </List.Item>
            )}
          />
        </Spin>
      </Form>
    );
  }
);
export default UserGroupUpdateUsersForm;
