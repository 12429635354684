import { Button } from 'antd';
import { CloseIcon } from 'assets';
import React, { useCallback } from 'react';
import { FileUpload } from 'types';
import { convertFileType, FILE_TYPE } from 'utils';
import ChatFile from './ChatFile';
import ChatImage from './ChatImage';

type ChatFileListProps = {
  fileList: FileUpload[];
  handleRemove: (file: FileUpload, index: number) => void;
};

const ChatFileList: React.FC<ChatFileListProps> = ({ fileList, handleRemove }) => {
  const renderFiles = useCallback(() => {
    return fileList.map((file, index) => {
      let content;
      switch (convertFileType(file.type)) {
        case FILE_TYPE.IMAGE:
          content = <ChatImage file={file} />;
          break;
        default:
          content = <ChatFile file={file} />;
      }
      return (
        <div className='relative'>
          {content}

          <Button
            onClick={() => handleRemove(file, index)}
            size='small'
            shape='circle'
            className='hover:bg-textHoverBg absolute -right-2 -top-1 z-10 h-5 w-5 min-w-0 bg-colorBgContainerHover p-0 text-sm text-white'
            type='text'
            icon={<CloseIcon className='h-2 w-2' />}
          />
        </div>
      );
    });
  }, [fileList.length]);
  if (!fileList || fileList.length === 0) return null;
  return (
    <div className='w-fit max-w-full overflow-x-auto py-2'>
      <div className='relative flex gap-2 pr-3'>{renderFiles()}</div>
    </div>
  );
};

export default ChatFileList;
