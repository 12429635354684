import { io } from 'socket.io-client';
import { store } from 'store';
import { receiveMessage } from 'store/features';
import { JoinConversationDto, MessageDto, OutConversationDto, SendMessageDto, TypingDto, UserTypingDto } from 'types';
import { CHAT_SOCKET_EVENT, configuration, LOCAL_STORAGE_KEY, refreshTokenApi } from 'utils';
let refreshCount = 5;
export const internalChatSocketClient = io(configuration.CHAT_SOCKET_URL, {
  transports: ['websocket'],
  auth: {
    token: localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
  }
});

internalChatSocketClient.on(CHAT_SOCKET_EVENT.CONNECT, () => {
  refreshCount = 5;
});

internalChatSocketClient.on(CHAT_SOCKET_EVENT.CONNECT_ERROR, (error) => {
  refreshCount -= 1;
  reconnect();
});

internalChatSocketClient.on(CHAT_SOCKET_EVENT.UNAUTHORIZED, (error) => {
  refreshCount -= 1;
  reconnect();
});

async function reconnect() {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);

  if (refreshToken && refreshCount > 0) {
    const responseToken = await refreshTokenApi(refreshToken, 'internal-socket');
    if (responseToken?.data.accessToken) {
      const accessToken = responseToken.data.accessToken;
      internalChatSocketClient.auth = {
        token: accessToken
      };
      internalChatSocketClient.connect();
    }
  }
}
export async function disconnectInternalChatSocket() {
  internalChatSocketClient.disconnect();
}
export function onReceiveMessage(callback: (data: MessageDto) => void) {
  internalChatSocketClient.on(CHAT_SOCKET_EVENT.RECEIVE_MESSAGE, (data) => {
    store.dispatch(receiveMessage(data));
    callback(data);
  });
}

export function offReceiveMessage() {
  internalChatSocketClient.off(CHAT_SOCKET_EVENT.RECEIVE_MESSAGE);
}

export function sendMessage(sendMessageDto: SendMessageDto) {
  internalChatSocketClient.emit(CHAT_SOCKET_EVENT.SEND_MESSAGE, sendMessageDto);
}

export function joinConversation(joinConversationDto: JoinConversationDto) {
  internalChatSocketClient.emit(CHAT_SOCKET_EVENT.JOIN_CONVERSATION, joinConversationDto);
}

export function outConversation(outConversationDto: OutConversationDto) {
  internalChatSocketClient.emit(CHAT_SOCKET_EVENT.OUT_CONVERSATION, outConversationDto);
}

export function typing(typingDto: TypingDto) {
  internalChatSocketClient.emit(CHAT_SOCKET_EVENT.TYPING, typingDto);
}

export function onTyping(callback: (data: UserTypingDto) => void) {
  internalChatSocketClient.on(CHAT_SOCKET_EVENT.USER_TYPING, callback);
}
export function offTyping() {
  internalChatSocketClient.off(CHAT_SOCKET_EVENT.USER_TYPING);
}
