import { Layout, Spin } from 'antd';
import { ActiveShiftsModal, Header, Loadable, SecondSiderMenu, SidebarMenu } from 'components';
import { useGetShiftMeActive, useProfile } from 'hooks';
import { shiftsMessages } from 'messages';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { LOCAL_STORAGE_KEY } from 'utils';

const { Sider } = Layout;

function MainLayout() {
  const { isAuthenticated } = useProfile();
  const { isLoading: isLoadingShiftMeActive } = useGetShiftMeActive();

  if (!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || !isAuthenticated) {
    return <Navigate to={ROUTE.LOGIN} replace />;
  }

  return (
    <Spin tip={shiftsMessages.loadShift} spinning={isLoadingShiftMeActive}>
      <Layout>
        <Sider className='sider-layout'>
          <div className='relative'>
            <div className='absolute left-0 z-[100]'>
              <SidebarMenu />
            </div>
            <SecondSiderMenu />
          </div>
        </Sider>
        <Layout>
          <Header />
          <Layout className='h-[calc(100vh-80px)] max-h-[calc(100vh-80px)] overflow-y-auto px-8 pb-5'>
            <Loadable>
              <Outlet />
            </Loadable>
          </Layout>
        </Layout>
        <ActiveShiftsModal />
      </Layout>
    </Spin>
  );
}

export default MainLayout;
