import { Button, Modal, Skeleton, Typography } from 'antd';
import { message } from 'components/common';
import dayjs from 'dayjs';
import { internalAnnouncementsMessages, messages } from 'messages';
import { useEffect, useRef, useState } from 'react';
import { useConfirmInternalAnnouncementMutation, useGetInternalAnnouncementDetailActiveQuery } from 'services';
import { DATE_FORMAT } from 'utils';
import InternalAnnouncementDetailActive from './InternalAnnouncementActiveDetail';
import InternalAnnouncementsActive from './InternalAnnouncementsActive';
type InternalAnnouncementsModalProps = {
  open: boolean;
  onCancel: () => void;
};
const { confirm } = Modal;

const InternalAnnouncementsModal: React.FC<InternalAnnouncementsModalProps> = ({ open, onCancel }) => {
  const [announcementId, setAnnouncementId] = useState<number>();
  const {
    data: internalAnnouncement,
    error,
    isLoading: isLoadingDetail
  } = useGetInternalAnnouncementDetailActiveQuery(announcementId!, {
    skip: !announcementId,
    refetchOnMountOrArgChange: true
  });
  const data = internalAnnouncement?.data;
  const isRequestConfirm = data?.isRequestConfirm && !data.confirmedAt;
  const contentRef = useRef<HTMLDivElement>(null);

  const [confirmAnnouncement, { isLoading: isLoadingConfirm }] = useConfirmInternalAnnouncementMutation();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [announcementId]);

  const handleConfirm = () => {
    if (announcementId) {
      confirmAnnouncement(announcementId)
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
        });
    }
  };

  const handleCloseOrChangeAnnouncement = (newAnnouncementId?: number) => {
    confirm({
      title: `${internalAnnouncementsMessages.closeAnnouncement} `,
      content: internalAnnouncementsMessages.confirmCloseAnnouncement,
      cancelText: messages.cancelButtonText,
      okText: messages.confirmButtonText,
      onOk: () => {
        if (newAnnouncementId) {
          setAnnouncementId(newAnnouncementId);
        } else {
          onCancel();
        }
      }
    });
  };
  return (
    <Modal
      styles={{
        body: {
          padding: 0,
          borderRadius: 12,
          overflow: 'hidden'
        }
      }}
      footer={null}
      centered
      destroyOnClose
      width={'60vw'}
      open={open}
      onCancel={() => {
        if (isRequestConfirm) {
          handleCloseOrChangeAnnouncement();
        } else {
          onCancel();
        }
      }}
      title={null}
    >
      <div className='flex h-[calc(100vh-80px)]'>
        <div className='flex h-full max-h-[calc(100vh-80px)] w-1/3 overflow-y-auto bg-colorBgContainerSecondary'>
          <InternalAnnouncementsActive
            internalAnnouncementId={announcementId}
            setAnnouncementId={(id) => {
              if (isRequestConfirm && data.internalAnnouncementId !== id) {
                handleCloseOrChangeAnnouncement(id);
              } else {
                setAnnouncementId(id);
              }
            }}
          />
        </div>
        <div ref={contentRef} className='relative h-full max-h-[calc(100vh-80px)] w-2/3 overflow-y-auto'>
          {isLoadingDetail ? (
            <Skeleton active></Skeleton>
          ) : error ? (
            <div className='flex h-full items-center justify-center'>
              <Typography.Text>{error.message}</Typography.Text>
            </div>
          ) : data ? (
            <InternalAnnouncementDetailActive data={data} loading={isLoadingDetail} />
          ) : (
            <></>
          )}
          {data && (
            <div className='sticky bottom-0 flex h-14 w-full items-center gap-4 border-t border-colorBorder bg-white p-4'>
              {isRequestConfirm && (
                <Button loading={isLoadingConfirm} onClick={handleConfirm} type='primary'>
                  {messages.confirmButtonText}
                </Button>
              )}
              {data?.confirmedAt && (
                <Typography.Text>
                  {internalAnnouncementsMessages.confirmedAt}: {dayjs(data.confirmedAt).format(DATE_FORMAT)}
                </Typography.Text>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default InternalAnnouncementsModal;
