import { SendOutlined } from '@ant-design/icons';
import { Button, message as messageAntd } from 'antd';
import { messages } from 'messages';
import React, { useRef, useState } from 'react';
import { EmojiProps, FileUpload } from 'types';
import { execCommand, moveCaretToEnd } from 'utils';
import ChatAttach from './ChatAttach';
import ChatEmoji from './ChatEmoji';
import ChatFileList from './ChatFileList';
import ChatInput, { ChatInputRefProps } from './ChatInput';

type ChatBoxProps = {
  onSendMessage: (message: string) => void;
  onSendFiles: (image: FileUpload[]) => void;
};

const ChatBox: React.FC<ChatBoxProps> = ({ onSendMessage, onSendFiles }) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<FileUpload[]>([]);
  const chatInputRef = useRef<ChatInputRefProps>(null);

  const [messageApi, contextHolder] = messageAntd.useMessage();

  const handleSend = () => {
    if (message.trim()) {
      const messageToSend = message.replace(/\n/g, '<br />');
      onSendMessage(messageToSend);
      setMessage('');
    }
    if (files.length > 0) {
      onSendFiles(files);
      setFiles([]);
    }
    if (chatInputRef.current && chatInputRef.current.input) {
      chatInputRef.current.input.innerHTML = '';
    }
  };

  const beforeUpload = (file: FileUpload) => {
    if (file.size > 1000000) {
      messageApi.error(messages.fileSizeIsMax);
      return false;
    }
    return true;
  };

  const handleRemoveFile = (_file: FileUpload, index: number) => {
    setFiles(files.filter((_file, idx) => idx !== index));
  };

  const handleSelectEmoji = (emoji: EmojiProps) => {
    if (emoji.native) {
      setMessage(message + emoji.native);

      if (chatInputRef.current && chatInputRef.current.input) {
        moveCaretToEnd(chatInputRef.current.input);
        execCommand(emoji.native);
      }
    }
  };
  return (
    <div>
      {contextHolder}
      <div
        className='flex gap-2'
        onClick={() => {
          if (chatInputRef.current?.input) {
            if (document.activeElement !== chatInputRef.current?.input) {
              moveCaretToEnd(chatInputRef.current.input);
            }
          }
        }}
      >
        <ChatAttach
          beforeUpload={beforeUpload}
          attachFile={(file) => {
            setFiles([...files, file]);
          }}
        />
        <div className='flex w-full cursor-text flex-col gap-2 rounded-lg border border-colorBorderInput px-3 py-2'>
          <ChatFileList fileList={files} handleRemove={handleRemoveFile} />
          <div className='flex w-full flex-1 items-center'>
            <ChatInput
              ref={chatInputRef}
              attachFile={(file) => {
                setFiles([...files, file]);
              }}
              handleSend={handleSend}
              message={message}
              setMessage={setMessage}
              beforeUpload={beforeUpload}
            />
            <ChatEmoji handleSelect={handleSelectEmoji} />
          </div>
        </div>
        <Button
          type='text'
          className='h-10 w-10'
          shape='circle'
          icon={<SendOutlined className='text-colorPrimary' />}
          onClick={handleSend}
        ></Button>
      </div>
    </div>
  );
};

export default ChatBox;
