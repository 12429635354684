import { useState } from 'react';
import { useGetMessagesQuery } from 'services';
import { FindAllMessageDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useMessagesPaging({
  keyword,
  type,
  conversationId
}: Omit<FindAllMessageDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: messagesRes,
    error,
    isLoading,
    isFetching,
    refetch
  } = useGetMessagesQuery({
    pageIndex: pageIndex,
    pageSize,
    conversationId,
    keyword,
    type
  });

  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    messages: messagesRes?.data.rows || [],
    count: messagesRes?.data.count || 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    refetch: refetch,
    handleChangePage,
    hasMore: messagesRes && messagesRes.data.rows.length < messagesRes.data.count
  };
}
