import { Button, Empty, Form, Input, List, Modal, ModalProps, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Avatar, FormItem } from 'components/common';
import { SelectUsers } from 'components/users';
import { internalChatMessages, messages, usersMessages, validateMessages } from 'messages';
import { useEffect, useState } from 'react';
import { CreateGroupConversationDto, FileDto, UserCompactDto } from 'types';
import { internalChatValidationRules } from 'utils';

type CreateGroupConversationModalProps = ModalProps & {
  onSubmit: (data: CreateGroupConversationDto) => void;
};

const CreateGroupConversationModal: React.FC<CreateGroupConversationModalProps> = ({
  confirmLoading,
  onSubmit,
  ...props
}) => {
  const [form] = Form.useForm<CreateGroupConversationDto>();
  const [participants, setParticipants] = useState<UserCompactDto[]>([]);
  const fileId = Form.useWatch('image', form);
  const name = Form.useWatch('name', form);

  const onUpdateAvatarHandle = (value?: FileDto) => {
    if (value) {
      form.setFieldValue('image', value.fileId);
    }
  };

  const handleAdd = (record: DefaultOptionType & UserCompactDto) => {
    setParticipants([
      {
        userId: record.userId,
        fullName: record.fullName,
        shortName: record.shortName,
        avatar: record.avatar
      },
      ...participants.filter((o) => o.userId !== record.userId)
    ]);
  };

  const handleRemove = (record: UserCompactDto) => {
    setParticipants(participants.filter((o) => o.userId !== record.userId));
  };

  useEffect(() => {
    if (!props.open) {
      form.resetFields();
    }
  }, [props.open]);

  return (
    <Modal
      {...props}
      footer={[
        <Button
          loading={confirmLoading}
          onClick={() => {
            form.setFieldValue(
              'participants',
              participants.map((p) => p.userId)
            );
            form.submit();
          }}
          size='large'
          type='primary'
        >
          {messages.createButtonText}
        </Button>
      ]}
    >
      <Typography.Title level={3}>{internalChatMessages.createNewGroupConversation}</Typography.Title>
      <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
        <div className='mb-2 flex w-full justify-center'>
          <Avatar.UploadOnlyCrop fileId={fileId} name={name} onUploadSuccess={onUpdateAvatarHandle} size={104} />
        </div>
        <FormItem.FloatLabel<CreateGroupConversationDto>
          name='name'
          label={internalChatMessages.name}
          rules={internalChatValidationRules.name}
        >
          <Input />
        </FormItem.FloatLabel>
        <Form.Item name='image' noStyle rules={internalChatValidationRules.image} />
        <Typography.Title level={5}>{internalChatMessages.participants}</Typography.Title>
        <FormItem<CreateGroupConversationDto>
          name='participants'
          rules={internalChatValidationRules.participants}
          className='hide-label mb-6'
          label={internalChatMessages.participants}
        >
          <SelectUsers
            onSelect={(_value, record) => {
              const item = record as DefaultOptionType & UserCompactDto;
              handleAdd(item);
            }}
            placeholder={usersMessages.fullName}
            className='w-full'
            value={null}
          />
        </FormItem>
        <List
          className='max-h-[300px] min-h-[200px] overflow-auto'
          itemLayout='horizontal'
          dataSource={participants}
          rowKey={(item) => item.userId!}
          locale={{
            emptyText: <Empty />
          }}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Button
                  danger
                  type='text'
                  size='small'
                  onClick={() => {
                    handleRemove(item);
                  }}
                  className='text-sm'
                >
                  {messages.deleteButtonText}
                </Button>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar size={40} name={item.shortName} fileId={item.avatar} />}
                title={<Typography.Text>{item.shortName}</Typography.Text>}
                description={<Typography.Text>{item.fullName}</Typography.Text>}
              />
            </List.Item>
          )}
        />
      </Form>
    </Modal>
  );
};

export default CreateGroupConversationModal;
