export const shiftsMessages = {
  create: 'Tạo mới ca trực',
  update: 'Cập nhật ca trực',
  shiftId: 'ID ca trực',
  code: 'Mã quản lý',
  name: 'Tên ca trực',
  fromTime: 'Thời gian bắt đầu',
  toTime: 'Thời gian kết thúc',
  time: 'Thời gian',
  loadShift: 'Tải ca trực',
  activeShift: 'Chọn ca trực',
  startShifts: 'Bắt đầu ca',
  pleaseSelectShift: 'Vui lòng chọn ca',
  notHasShiftValid: 'Hiện tại không có ca trực hoạt động',
  startWithoutShift: 'Bắt đầu làm việc không có ca trực'
};
