import React, { useEffect, useRef } from 'react';

type PropsType = {
  className?: string;
  style?: React.CSSProperties;
  height?: string | number;
  maxHeight?: string | number;
  loader?: React.ReactNode;
  endMessage?: React.ReactNode;
  hasMore: boolean;
  isLoading?: boolean;
  next: () => void;
  children: React.ReactNode;
};

function InfiniteScroll({
  className,
  style,
  height,
  maxHeight,
  loader,
  endMessage,
  hasMore,
  isLoading,
  next,
  children
}: PropsType) {
  const pageEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !isLoading) {
        try {
          next();
        } catch (error) {
          console.error('Error loading next page:', error);
        }
      }
    });

    if (pageEndRef.current) {
      observer.observe(pageEndRef.current);
    }

    return () => {
      if (pageEndRef.current) {
        observer.unobserve(pageEndRef.current);
      }
    };
  }, [hasMore, isLoading, next]);

  return (
    <div
      className={className}
      style={{
        height,
        maxHeight,
        overflow: height || maxHeight ? 'auto' : 'hidden',
        ...style
      }}
    >
      {children}
      <div ref={pageEndRef}>{hasMore ? loader : endMessage}</div>
    </div>
  );
}

export default InfiniteScroll;
