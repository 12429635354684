import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageDto } from 'types';

interface InternalChatState {
  conversations: {
    conversationId: string;
    messages: MessageDto[];
  }[];
}

const initialState: InternalChatState = {
  conversations: []
};

const internalChatSlice = createSlice({
  name: 'internalChat',
  initialState,
  reducers: {
    receiveMessage: (state, actions: PayloadAction<MessageDto>) => {
      const message = actions.payload;
      const conversation = state.conversations.find(
        (conversation) => conversation.conversationId === message.conversationId
      );
      if (conversation) {
        conversation.messages.push(message);
      } else {
        state.conversations.push({
          conversationId: message.conversationId,
          messages: [message]
        });
      }
    }
  },
  selectors: {
    selectMessagesByConversationId: (state, conversationId) =>
      state.conversations.find((conversation) => conversation.conversationId === conversationId)?.messages ?? []
  }
});

export const { receiveMessage } = internalChatSlice.actions;

export const { selectMessagesByConversationId } = internalChatSlice.selectors;

export const internalChatReducer = internalChatSlice.reducer;
