import { Typography } from 'antd';
import { Avatar, File } from 'components/common';
import dayjs from 'dayjs';
import { messages as translateMessages } from 'messages';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { twMerge } from 'tailwind-merge';
import { ConversationParticiPantDto, MessageDto } from 'types';
import { MESSAGE_TYPE } from 'utils';

type MessageItemProps = {
  data: MessageDto;
  showDate: boolean;
  sender?: ConversationParticiPantDto;
};

const MessageItem: React.FC<MessageItemProps> = ({ data, showDate, sender }) => {
  const profile = useSelector((state: RootState) => state.users.userProfile);

  const renderMessage = () => {
    switch (data.type) {
      case MESSAGE_TYPE.TEXT:
      case MESSAGE_TYPE.STICKER:
        return (
          <div
            className={twMerge(
              'flex items-end rounded-3xl px-4 py-1',
              data.senderId === profile?.userId ? 'bg-colorPrimaryBg' : 'bg-colorBgBody'
            )}
          >
            <div className='mb-1 mr-4'>
              <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
            <Typography.Text className='text-xs' type='secondary'>
              {dayjs(data.sentAt).format('HH:mm')}
            </Typography.Text>
          </div>
        );
      case MESSAGE_TYPE.IMAGE:
      case MESSAGE_TYPE.FILE:
        return (
          <div className='relative'>
            <File.Server fileId={Number(data.content)} className='max-w-[200px]' />
            <div className='absolute bottom-1 right-1 rounded-2xl bg-textHoverBg px-1'>
              <Typography.Text className='text-xs' type='secondary'>
                {dayjs(data.sentAt).format('HH:mm')}
              </Typography.Text>
            </div>
          </div>
        );
    }
  };

  return (
    <div className='w-full text-center'>
      {showDate && (
        <Typography.Text type='secondary' className='text-xs font-semibold'>
          {dayjs(data.sentAt).isSame(dayjs(), 'date')
            ? translateMessages.today
            : dayjs(data.sentAt).format('DD, MMM, YYYY')}
        </Typography.Text>
      )}
      <div className={twMerge('flex items-end gap-x-1', data.senderId === profile?.userId ? 'flex-row-reverse' : '')}>
        {sender ? <Avatar fileId={sender.image} name={sender.name} /> : <div className='w-[24px]'></div>}
        {renderMessage()}
      </div>
    </div>
  );
};

export default memo(MessageItem);
