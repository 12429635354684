import React from 'react';

type ImageProps = {
  url?: string;
  alt?: string;
};
const Image: React.FC<ImageProps> = ({ url, alt }) => {
  return <img loading='lazy' className='h-full w-auto min-w-[92px] rounded-sm object-contain' src={url} alt={alt} />;
};
export default Image;
