import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateOrganizationUnitDto,
  FindAllOrganizationUnitDto,
  FindOrganizationUnitHierarchyDto,
  OrganizationUnitCompactDto,
  OrganizationUnitDto,
  OrganizationUnitHierarchyDto,
  ResponsePagingDto,
  ResponseSuccessDto,
  UpdateOrganizationUnitDto
} from 'types';
import { removeEmptyChildren } from 'utils';
import axiosBaseQuery from 'utils/base-api';

export const organizationUnitsApi = createApi({
  reducerPath: 'organizationUnitsApi',
  tagTypes: ['organization_units', 'organization_units_detail', 'organization_units_params'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getOrganizationUnits: builder.query<ResponsePagingDto<OrganizationUnitDto>, FindAllOrganizationUnitDto>({
      query: (params) => ({
        url: '/organization_units',
        method: 'get',
        params
      }),

      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ organizationUnitId }) => ({
              type: 'organization_units',
              id: organizationUnitId
            }))
          : ['organization_units']
    }),

    getOrganizationUnitsHierarchy: builder.query<
      ResponseSuccessDto<OrganizationUnitHierarchyDto[]>,
      FindOrganizationUnitHierarchyDto
    >({
      query: (params) => ({
        url: '/organization_units/hierarchy',
        method: 'get',
        params
      }),
      transformResponse: (res: ResponseSuccessDto<OrganizationUnitHierarchyDto[]>) => ({
        ...res,
        data: removeEmptyChildren(res.data)
      }),
      providesTags: ['organization_units']
    }),

    getOrganizationUnitsOptions: builder.query<
      ResponsePagingDto<OrganizationUnitCompactDto>,
      FindAllOrganizationUnitDto
    >({
      query: (params) => ({
        url: '/organization_units/options',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.organizationUnitId?.length && queryArgs.organizationUnitId.length > 0) {
          return endpointName + '/organizationUnitId';
        }
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows.push(...newItems.data.rows);
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ organizationUnitId }) => ({
              type: 'organization_units',
              id: organizationUnitId
            }))
          : ['organization_units']
    }),

    getOrganizationUnitDetail: builder.query<ResponseSuccessDto<OrganizationUnitDto>, number>({
      query: (organizationUnitId) => ({ url: `/organization_units/${organizationUnitId}`, method: 'get' }),
      providesTags: (result) =>
        result ? [{ type: 'organization_units_detail', id: result.data.organizationUnitId }] : []
    }),

    addOrganizationUnit: builder.mutation<ResponseSuccessDto<OrganizationUnitDto>, CreateOrganizationUnitDto>({
      query: (newOrganizationUnit) => ({
        url: '/organization_units',
        method: 'post',
        data: newOrganizationUnit
      }),
      invalidatesTags: ['organization_units']
    }),
    updateOrganizationUnit: builder.mutation<ResponseSuccessDto<OrganizationUnitDto>, UpdateOrganizationUnitDto>({
      query: ({ organizationUnitId, ...updateOrganizationUnit }) => ({
        url: `/organization_units/${organizationUnitId}`,
        method: 'patch',
        data: updateOrganizationUnit
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'organization_units' },
        { type: 'organization_units', id: param.organizationUnitId },
        { type: 'organization_units_detail', id: param.organizationUnitId }
      ]
    }),
    deleteOrganizationUnit: builder.mutation<void, number>({
      query: (organizationUnitId) => ({
        url: `/organization_units/${organizationUnitId}`,
        method: 'delete'
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'organization_units' },
        { type: 'organization_units', id: param },
        { type: 'organization_units_detail', id: param }
      ]
    })
  })
});

export const {
  useGetOrganizationUnitsHierarchyQuery,
  useLazyGetOrganizationUnitsOptionsQuery,
  useGetOrganizationUnitsOptionsQuery,
  useGetOrganizationUnitsQuery,
  useGetOrganizationUnitDetailQuery,
  useAddOrganizationUnitMutation,
  useUpdateOrganizationUnitMutation,
  useDeleteOrganizationUnitMutation
} = organizationUnitsApi;
