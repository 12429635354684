import { Button, Empty, Form, Input, List, Modal, ModalProps, Spin, Typography } from 'antd';
import Select, { DefaultOptionType } from 'antd/es/select';
import { Avatar, FormItem } from 'components/common';
import { SelectUsers } from 'components/users';
import { internalChatMessages, messages, usersMessages, validateMessages } from 'messages';
import { useEffect, useState } from 'react';
import { useGetConversationDetailQuery } from 'services';
import { FileDto, UpdateGroupConversationDto, UserCompactDto } from 'types';
import { internalChatValidationRules } from 'utils';

type UpdateGroupConversationModalProps = ModalProps & {
  conversationId: string;
  onSubmit: (data: UpdateGroupConversationDto) => void;
};

const UpdateGroupConversationModal: React.FC<UpdateGroupConversationModalProps> = ({
  conversationId,
  confirmLoading,
  onSubmit,
  ...props
}) => {
  const { isLoading, data } = useGetConversationDetailQuery(conversationId);

  const [form] = Form.useForm<UpdateGroupConversationDto>();
  const [participants, setParticipants] = useState<UserCompactDto[]>([]);
  const fileId = Form.useWatch('image', form);

  const onUpdateAvatarHandle = (value?: FileDto) => {
    if (value) {
      form.setFieldValue('image', value.fileId);
    }
  };

  const handleAdd = (record: DefaultOptionType & UserCompactDto) => {
    setParticipants([
      {
        userId: record.userId,
        fullName: record.fullName,
        shortName: record.shortName,
        avatar: record.avatar
      },
      ...participants.filter((o) => o.userId !== record.userId)
    ]);
  };

  const handleRemove = (record: UserCompactDto) => {
    setParticipants(participants.filter((o) => o.userId !== record.userId));
  };

  useEffect(() => {
    if (data?.data) {
      form.setFieldsValue({
        conversationId: conversationId,
        name: data.data.name,
        participants: data.data.participants.map((p) => p.userId),
        image: data.data.image,
        adminId: data.data.adminId
      });
      setParticipants(
        data.data.participants.map((p) => ({
          userId: p.userId,
          fullName: p.name || '',
          shortName: p.name || '',
          avatar: p.image
        }))
      );
    }
  }, [data]);

  return (
    <Modal
      {...props}
      footer={[
        <Button
          loading={confirmLoading}
          onClick={() => {
            form.setFieldValue(
              'participants',
              participants.map((p) => p.userId)
            );
            form.submit();
          }}
          size='large'
          type='primary'
        >
          {messages.saveButtonText}
        </Button>
      ]}
    >
      <Typography.Title level={3}>{internalChatMessages.updateGroupConversation}</Typography.Title>
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
          <div className='mb-2 flex w-full justify-center'>
            <Avatar.UploadOnlyCrop
              onUploadSuccess={onUpdateAvatarHandle}
              size={104}
              fileId={fileId}
              name={data?.data.name}
            />
          </div>
          <Form.Item name='conversationId' noStyle />
          <FormItem.FloatLabel<UpdateGroupConversationDto>
            name='name'
            label={internalChatMessages.name}
            rules={internalChatValidationRules.name}
          >
            <Input />
          </FormItem.FloatLabel>
          <Form.Item name='image' noStyle />
          <FormItem.FloatLabel<UpdateGroupConversationDto> name='adminId' label={internalChatMessages.adminId}>
            <Select options={data?.data.participants.map((p) => ({ value: p.userId, label: p.name }))} />
          </FormItem.FloatLabel>

          <Typography.Title level={5}>{internalChatMessages.participants}</Typography.Title>
          <FormItem<UpdateGroupConversationDto>
            name='participants'
            rules={internalChatValidationRules.participants}
            className='hide-label mb-6'
            label={internalChatMessages.participants}
          >
            <SelectUsers
              onSelect={(_value, record) => {
                const item = record as DefaultOptionType & UserCompactDto;
                handleAdd(item);
              }}
              placeholder={usersMessages.fullName}
              className='w-full'
              value={null}
            />
          </FormItem>
          <List
            className='max-h-[300px] min-h-[200px] overflow-auto'
            itemLayout='horizontal'
            dataSource={participants}
            rowKey={(item) => item.userId!}
            locale={{
              emptyText: <Empty />
            }}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    danger
                    type='text'
                    size='small'
                    onClick={() => {
                      handleRemove(item);
                    }}
                    className='text-sm'
                  >
                    {messages.deleteButtonText}
                  </Button>
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar size={40} name={item.shortName} fileId={item.avatar} />}
                  title={<Typography.Text>{item.shortName}</Typography.Text>}
                  description={<Typography.Text>{item.fullName}</Typography.Text>}
                />
              </List.Item>
            )}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateGroupConversationModal;
